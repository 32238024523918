<template>
  <div id="companyNewsDetail">
    <header-comp></header-comp>
    <section class="topBg">
      <h3>公司动态</h3>
      <p>查看关于公司及业内最新资讯动态</p>
      <img :src="isMobile?urlMobile:url" alt="关于我们" />
    </section>
    <section class="gsdtContent" v-if="detailInfo">
      <div class="centerContent">
        <span class="back" @click="backClick"><i class="el-icon-arrow-left"></i>返回公司动态</span>
        <h2>{{ detailInfo?.title }}</h2>
        <h3>
          <span>{{ detailInfo?.createTime.split(' ')[0].replaceAll('-','/') }}</span>
          <span>来源：蜜蜂优车</span>
          <span><a :href="detailInfo?.remark" target="_blank">查看原文</a></span>
        </h3>
        <div class="content" v-html="detailInfo?.content"></div>
      </div>
    </section>
    <footer-comp></footer-comp>
  </div>
</template>

<script type="text/javascript">
import headerComp from '@/views/components/headerComp'
import footerComp from '@/views/components/footerComp'
import { getCompanyNewsDetail } from '@/api/index'
export default {
  name: "companyNewsDetail",
  components: {
    headerComp, footerComp
  },
  data() {
    return {
      url: require('../assets/images/companyNewBg.png'),
      urlMobile: require('../assets/images/mobile/dongtai_dongtai_normal.png'),
      detailInfo: null
    }
  },
  computed: {
    id() {
      return this.$route.query ? this.$route.query.id : ''
    },
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
  },
  methods: {
    backClick() {
      this.$router.go(-1)
    },
    getInfo(id) {
      getCompanyNewsDetail(id).then(res => {
        this.detailInfo = res.data
      })
    }
  },
  created() {
  },
  mounted() {
    this.getInfo(this.id)
  },
}
</script>

<style lang="less" scoped>
@import '../assets/css/style.default.css';
@import '../assets/css/custom.css';
.topBg {
  width: 100%;
  height: 240px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.contactUs {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 65% 100%;
  }

  @media (min-width:992px) {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3,
  p {
    position: absolute;
    left: 50px;
  }

  h3 {
    font-size: 32px;
    top: 82px;
  }

  p {
    font-size: 16px;
    top: 138px;
  }

  @media (min-width:992px) {

    h3,
    p {
      position: absolute;
      left: 360px;
    }

    h3 {
      font-size: 32px;
      top: 82px;
    }

    p {
      font-size: 16px;
      top: 138px;
    }
  }
}
#companyNewsDetail {
  position: relative;
  width: 100%;
}

@media (min-width:992px) {
  #companyNewsDetail {
    position: relative;
    padding-top: 182px;
    width: 100%;
  }
}

.gsdtContent {
  min-height: 500px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

  .centerContent {
    max-width: 760px;
    width: 100%;
    margin: 20px auto 0;

    .back {
      cursor: pointer;
    }

    h2 {
      color: #1a1a1a;
      margin-bottom: 20px;
      margin-top: 36px;
    }

    h3 {
      span {
        font-size: 14px;
        color: #999;
        font-weight: normal;
        margin-right: 20px;
      }
    }

    .content {
      margin-top: 36px;
      width: 100%;

      /deep/p {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
